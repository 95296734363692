import { toast } from 'react-toastify';
import axios from 'axios';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import * as yup from 'yup';
import { MAP_SETTINGS, SYSTEM_START_DATE } from 'constants/constants';
import translations from 'constants/translations';
import enums from 'enums/index';

export const getDateString = (d, locale = 'de', includeTime = false) => {
  var date = new Date(d);

  const dateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const timeOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return (
    (includeTime ? date.toLocaleTimeString(locale, timeOptions) + ', ' : '') +
    date.toLocaleDateString(locale, dateOptions)
  );
};

export const flatten = arr => {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten);
  }, []);
};

export const setLocaleCookie = lang => {
  document.cookie = `i18next=${lang}; expires=Fri, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const createValidationSchema = formStructure => {
  let validationObj = {};
  if (formStructure) {
    for (let i = 0; i < formStructure.length; i++) {
      for (let j = 0; j < formStructure[i].fields.length; j++) {
        validationObj[formStructure[i].fields[j].name] = formStructure[i].fields[j].validation;
      }
    }
  }
  return yup.object(validationObj);
};

export const setObjectProp = (obj, path, value) => {
  const pList = path.split('.');
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};

export const setPageSettingsProp = (propPath, value) => {
  let settings = JSON.parse(localStorage.getItem('settings'));
  if (settings === null) {
    settings = {};
  }
  setObjectProp(settings, propPath, value);
  localStorage.setItem('settings', JSON.stringify(settings));
};

export const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const isPrioritized = helper => moment().isBetween(helper?.prioritizedStartDate, helper?.prioritizedEndDate);

export const getHelperMarker = ({ helper, settings }) => {
  const status = settings.applyCanAcceptClientsMarkers
    ? helper?.canAcceptClient
    : settings.applyIdleHelpers
    ? !helper?.hasAcceptedInvitation || isPrioritized(helper)
    : helper?.invitationStatus;
  let imgName = '';
  if (settings?.applyCanAcceptClientsMarkers) {
    if (status) imgName += 'green-person-pin';
    else imgName += 'red-person-pin';
  } else if (settings?.applyIdleHelpers) {
    if (status) imgName += 'purple-person-pin';
    else imgName += 'blue-person-pin';
  } else if (settings?.applyStageCreatedAt) {
    // if (helper?.isNew) imgName = 'new-';
    switch (helper.invitationStageCreatedAt) {
      case enums.AutomaticInvitationStages.PREFERRED_MATCHING:
        imgName += 'blue-person-pin';
        break;
      case enums.AutomaticInvitationStages.PREFERENCES_COMPROMISE:
        imgName += 'green-person-pin';
        break;
      case enums.AutomaticInvitationStages.RADIUS_COMPROMISE:
        imgName += 'orange-person-pin';
        break;
      case enums.AutomaticInvitationStages.BONUS_COMPROMISE:
        imgName += 'purple-person-pin';
        break;
      default:
        imgName += 'red-person-pin';
        break;
    }
  } else if (settings?.applyInvitationStatus) {
    if (helper?.isNew) imgName = 'new-';
    if (status === enums.InvitationStatus.PENDING) imgName += 'orange-person-pin';
    else if (status === enums.InvitationStatus.ACCEPTED || status === enums.InvitationStatus.MATCHED) {
      imgName += 'green-person-pin';
    } else if (status == enums.InvitationStatus.REJECTED) {
      imgName += 'red-person-pin';
    } else {
      imgName += 'blue-person-pin';
    }
  } else {
    if (helper?.isNew) imgName = 'new-';
    imgName += 'blue-person-pin';
  }

  return require(`assets/img/icons/${imgName}.png`);
};
export const getHelperMarkerSize = ({ helper, settings }) => {
  return new window.google.maps.Size(
    (MAP_SETTINGS.MARKER_HEIGHT +
      (helper?.isNew &&
      !settings.applyCanAcceptClientsMarkers &&
      !settings.applyStageCreatedAt &&
      !settings.applyIdleHelpers
        ? 20
        : 0)) *
      0.71,
    MAP_SETTINGS.MARKER_HEIGHT +
      (helper?.isNew &&
      !settings.applyCanAcceptClientsMarkers &&
      !settings.applyStageCreatedAt &&
      !settings.applyIdleHelpers
        ? 20
        : 0),
  );
};
export const replaceUrlSearchParam = (currentSearch, param, value) => {
  let search = new URLSearchParams(currentSearch);
  search.set(param, value);

  return search?.toString();
};

export const getDateTimeString = isoString => {
  const date = new Date(isoString);
  const splitDate = date
    .toLocaleString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    })
    .split(', ');

  return splitDate[0].split('.').reverse().join('-') + 'T' + splitDate[1].substr(0, 5);
};

export const getSizeString = (bytes, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export const detectBrowser = () => {
  try {
    // Opera 8.0+
    const isOpera =
      (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera) return 'Opera';

    // Firefox 1.0+
    const isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox) return 'Firefox';

    // Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || (typeof window['safari'] !== 'undefined' && window['safari']?.pushNotification));
    if (isSafari) return 'Safari';

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE) return 'IE';

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    if (isEdge) return 'Edge';
  } catch {
    return undefined;
  }
};

export const changeMoneyFormat = (number, lang = 'de', n = 2) => {
  let numberAsString = number ? number.toString() : '0';
  let arr = numberAsString?.split('.');
  let integer = arr[0];
  let fraction = arr[1];

  let newNumber = '';
  for (let i = 1; i <= integer.length; i++) {
    newNumber += integer[i - 1];
    if (integer.length - i >= 3) {
      if ((integer.length - i) % 3 == 0 && i < integer.length) {
        newNumber += lang == 'de' ? '.' : ',';
      }
    }
  }
  if (fraction?.length) {
    newNumber += lang == 'de' ? ',' : '.';

    for (let i = 0; i < n; i++) {
      newNumber += i < fraction?.length ? fraction[i] : '';
    }
  }

  return newNumber;
};

export const zeroPadding = (number, length = 2) => {
  let updatedNumber = number.toString();
  for (let i = 0; i < length - updatedNumber.length; i++) {
    updatedNumber = '0' + updatedNumber;
  }
  return updatedNumber;
};

export const deepen = (obj, splitBy = '.') => {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split(splitBy);

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
};

export const flattenObject = data => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

export const injectFilterOperators = (filters, inputs) => {
  const newFilters = flattenObject(filters);
  inputs.forEach(input => {
    if (newFilters[input.name] !== '' && newFilters[input.name] !== undefined && newFilters[input.name] !== null) {
      let value;
      let name;
      switch (true) {
        case input.type === 'text':
          value = newFilters[input.name];
          delete newFilters[input.name];
          newFilters[`${input.name}.regex`] = value;
          break;
        case input.operation === 'range' && input.name.split('.').reverse()[0] === 'from':
          name = input.name.split('.').slice(0, -1).join();
          value = [newFilters[input.name], newFilters[name + '.to']];
          delete newFilters[input.name];
          delete newFilters[name + '.to'];
          newFilters[`${name}.range`] = value;
          break;
      }
    } else {
      delete newFilters[input.name];
    }
  });
  return deepen(newFilters);
};

export const isEmptyObject = obj => {
  return Object.keys(obj).every(
    key => obj[key] === '' || obj[key] === null || obj[key] === undefined || obj[key] === [] || obj[key] === {},
  );
};
export const getTasksNotes = (client, filtersState, i18n) => {
  if (filtersState.task != null) {
    let statuses;
    let dates;
    let dateObj;
    switch (+filtersState.task) {
      case enums.ClientsTasks.REPLACEMENT:
        statuses = client?.replacementStatus?.map(status => translations[i18n.language].invitationsStatusObj[status]);
        return '{' + Array.from(new Set(statuses))?.join(',') + '}';
      case enums.ClientsTasks.NEEDS_HELPER:
        dates = client?.validStartDate?.map(date => {
          dateObj = new Date(date);
          return `${zeroPadding(dateObj.getDate())}-${zeroPadding(dateObj.getMonth() + 1)}-${dateObj.getFullYear()}`;
        });
        return '{' + dates?.join(',') + '}';
      default:
        return '';
    }
  }
  return '';
};

export const prevMonth = dateObj => {
  var tempDateObj = new Date(dateObj);
  tempDateObj.setMonth(tempDateObj.getMonth() - 1);
  return tempDateObj;
};

export const isEmpty = val =>
  typeof val === 'boolean' || typeof val === 'number'
    ? false
    : val === undefined || val === null || val === '' || (Array.isArray(val) && val.length === 0) || _.isEmpty(val);

export const getAge = dateString => {
  if (!dateString) return null;
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const getGender = clientGender => {
  if (clientGender === enums.Gender.MALE) {
    return 'Male';
  } else if (clientGender === enums.Gender.FEMALE) {
    return 'Female';
  } else {
    return 'None';
  }
};

export const setNextLocaleCookie = lang => {
  document.cookie = `i18next=${lang}; expires=Fri, 31 Dec 2099 23:59:59 GMT; path=/`;
};

export const getCookie = cname => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const checkMonthLimits = (newMonth, year) => {
  let newYear = year;
  if (newMonth > 12) {
    newMonth = 1;
    newYear++;
  } else if (newMonth < 1) {
    newMonth = 12;
    newYear--;
  }
  return { newMonth, newYear };
};

export const getToday = () => {
  const today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }

  if (mm < 10) {
    mm = '0' + mm;
  }
  return `${mm}-${dd}-${yyyy}`;
};

export const formatCurrency = amount => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const convertNumberToDesiredFormat = number => {
  if (Number.isNaN(number)) {
    return number; // Return NaN if the input is not a valid number
  }

  if (Number.isNaN(number) || Number.isInteger(number)) {
    return number; // If the number is already an integer, return it as is
  } else {
    return parseFloat(number.toFixed(2)); // Round to 2 decimal places for non-integer numbers
  }
};
export const fetchFileAndConvertToBlob = async url => {
  try {
    const response = await axios.get(url, { responseType: 'blob' });
    const blob = new Blob([response.data]);
    return blob;
  } catch (error) {
    console.error('Error fetching and converting file:', error);
    throw error;
  }
};

export const extractFileName = url => {
  const parts = url.split('/');
  const filename = parts[parts.length - 1];
  const filenameWithoutQuery = filename.split('?')[0];
  return filenameWithoutQuery;
};

export const isImage = name => {
  const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg'];
  const extension = name.split('.')[1];
  return imageTypes.includes(extension);
};

export const downloadFile = (url, fileName = undefined) => {
  const name = fileName || extractFileName(url);
  if (isImage(name)) {
    fetchFileAndConvertToBlob(url).then(blob => {
      saveAs(blob, name);
    });
  } else {
    saveAs(url, name);
  }
};

export const parseNullableNumber = number => (number === null || number === '' ? null : +number);

export const getClientHourRate = (date = moment()) => {
  date = moment(date);
  const may2024 = moment('2024-05-01').startOf('day');
  const jan2023 = moment('2023-01-01').startOf('day');
  const jan2025 = moment('2025-01-01').startOf('day');

  if (date.isSameOrAfter(jan2025)) return enums.ClientHourlyRate.PRICE_JANUARY_2025;
  if (date.isSameOrAfter(may2024)) return enums.ClientHourlyRate.PRICE_MAY_2024;
  if (date.isSameOrAfter(jan2023)) return enums.ClientHourlyRate.PRICE_JANUARY_2023;
  return enums.ClientHourlyRate.PRICE_MARCH_2022;
};

export const isDateValid = ({ newMonth, newYear }) => {
  const nextYear = moment().add(1, 'year').year();
  return newYear >= 2018 && newYear <= nextYear && newMonth > 0 && newMonth <= 12;
};

export const isDateInRange = dateString => {
  const inputDate = moment(dateString);
  const startDate = moment(SYSTEM_START_DATE);
  const endDate = moment().add(1, 'year').endOf('year');

  if (!inputDate.isValid()) {
    return false;
  }

  if (inputDate.isAfter(startDate) && inputDate.isBefore(endDate)) {
    return true;
  } else {
    const nextYear = moment().add(1, 'year').endOf('year').format('YYYY-MM-DD');
    toast.error(
      `Date must be between ${moment(SYSTEM_START_DATE).format('YYYY-MM-DD')} and ${moment(nextYear).format(
        'YYYY-MM-DD',
      )}`,
    );
    return false;
  }
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
