import React from 'react';

// nodejs library to set properties for components
// nodejs library that concatenates classes
// @material-ui/core components
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from 'assets/jss/material-dashboard-pro-react/components/customInputStyle.js';

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    disabled,
    inputRef,
    variant,
  } = props;

  const labelClasses = classNames({
    [' ' + classes.labelRootError]: error,
    [' ' + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: variant === 'underlined' && error,
    [classes.underlineSuccess]: variant === 'underlined' && success && !error,
    [classes.underline]: variant === 'underlined',
    [classes.whiteUnderline]: variant === 'underlined' && white,
    [classes.noUnderline]: variant === 'outlined' || variant === 'none',
  });

  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl);
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  let newInputProps = {
    max: inputProps && inputProps.max !== undefined ? inputProps.max : undefined,
    min: inputProps && inputProps.min !== undefined ? inputProps.min : undefined,
    step: inputProps?.type === 'number' ? 'any' : undefined,
  };

  var disabledOverlayClasses = classNames({
    [classes.disabledOverlay]: true,
    [classes.disabledOverlayActive]: disabled,
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <div className={disabledOverlayClasses} />
      {labelText !== undefined ? (
        <InputLabel
          shrink={
            inputProps.type === 'time' ||
            inputProps.type === 'date' ||
            inputProps.type === 'datetime-local' ||
            inputProps.shrink ||
            inputProps.value
              ? true
              : undefined
          }
          className={classes.labelRoot + ' ' + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        disabled={disabled}
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        inputRef={inputRef}
        ampm={false}
        {...inputProps}
        value={
          (inputProps.type === 'time' ||
            inputProps.type === 'date' ||
            inputProps.type === 'datetime-local' ||
            inputProps.type === 'month') &&
          inputProps.value === undefined
            ? ''
            : inputProps.value
        }
        inputProps={newInputProps}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + '-text'} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.defaultProps = {
  variant: 'underlined',
};

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  variant: PropTypes.oneOf(['outlined', 'underlined', 'none']),
  disabled: PropTypes.bool,
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};
